import { useStore } from 'lib/store';
import { useEffect } from 'react';

export const useWidgetOpen = () => {
    const isWidget = useStore((store) => store.isWidget);
    useEffect(() => {
        if (isWidget && typeof window !== 'undefined') {
            window.parent.postMessage({ type: '__EDDY_TRAVELS_CHAT_OPEN' }, '*');
        }
    }, [isWidget]);
};
