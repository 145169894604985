import { GTM_EVENTS } from 'lib/gtmEvents';
import { CHAT_PARAMS, GOOGLE_PARAMS, UTM_PARAMS, WIDGET_PARAMS, getParams } from 'lib/queryParams';
import { useStore } from 'lib/store';
import { User } from 'lib/types';
import { NextRouter } from 'next/router';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

export const useQueryParamsHandler = (
    router: NextRouter,
    sendRequest: (message: string) => void,
    user?: User
) => {
    const partnerId = useStore((store) => store.partnerId);
    useEffect(() => {
        // @ts-ignore
        const searchParams = new URLSearchParams(window.location.search);

        if (user && searchParams.get('processed') !== 'true') {
            TagManager.dataLayer({
                dataLayer: {
                    event: GTM_EVENTS.SESSION_INITIATION,
                    data: user,
                },
            });
            searchParams.set('processed', 'true');
            const queryParams = getParams(searchParams, {
                ...UTM_PARAMS,
                ...WIDGET_PARAMS,
                ...CHAT_PARAMS,
                ...GOOGLE_PARAMS,
            });
            const deeplinkText = searchParams.get('send');
            const deeplinkId = searchParams.get('dl');
            searchParams.delete('dl');
            searchParams.delete('send');
            searchParams.delete('share');
            searchParams.delete('auth_provider');
            const widgetParamKeys = Object.keys(WIDGET_PARAMS);
            router
                .replace(`${router.pathname}?${searchParams.toString()}`, undefined, {
                    shallow: true,
                })
                .then(() => {
                    if (
                        Object.keys(queryParams)
                            .filter((key) => !widgetParamKeys.includes(key))
                            .some((key) => !!queryParams[key])
                    ) {
                        if (deeplinkText) {
                            sendRequest(
                                JSON.stringify({
                                    text: deeplinkText,
                                    tracking_params: queryParams,
                                    partner_id: partnerId,
                                    click: true,
                                })
                            );
                        } else if (deeplinkId) {
                            sendRequest(
                                JSON.stringify({
                                    deeplink: deeplinkId,
                                    tracking_params: queryParams,
                                    partner_id: partnerId,
                                })
                            );
                        }
                    }
                });
        }
    }, [router.query, user]);
};
