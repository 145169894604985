import { ChatComponent } from 'components/chat/chat';
import { useChatWebSocket } from 'components/chat/hooks/UseChatWebSocket';
import ContentLayout from 'components/common/ContentLayout';
import { getAuthorizationTokenHeader } from 'lib/api';
import { useStore } from 'lib/store';
import { useLogin, useWidgetClickHandler } from 'lib/utils';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { useChannelLikesState, useUserState } from '../components/chat/hooks/UseGlobalState';
import { useQueryParamsHandler } from '../components/chat/hooks/UseQueryParamsHandler';
import { useSetRequireRegistration } from '../components/chat/hooks/UseSetRequireRegistration';
import { useWidgetOpen } from '../components/chat/hooks/useWidgetOpen';

const ChatPage: React.FC = () => {
    const user = useUserState((state) => state.user);
    const router = useRouter();
    const token = getAuthorizationTokenHeader();
    const { messages, sendMessage, sendRequest } = useChatWebSocket(
        !!user,
        false,
        undefined,
        token
    );
    const updateChannelLikes = useChannelLikesState((state) => state.updateChannelLikes);
    const setLanguageCode = useStore((store) => store.setLanguageCode);
    const login = useLogin();
    useEffect(() => {
        if (!user) {
            login();
        } else {
            updateChannelLikes(user.id);
            if (user.language_code) {
                setLanguageCode(user.language_code);
            }
        }
    }, [user]);
    useWidgetClickHandler(router);
    useQueryParamsHandler(router, sendRequest, user);
    useSetRequireRegistration();
    useWidgetOpen();

    return (
        <ContentLayout>
            <ChatComponent messages={messages} sendMessage={sendMessage} channelHandle={user?.id} />
        </ContentLayout>
    );
};

export default ChatPage;
